import { request } from '@/utils/http'

export const getOfficialCourseware = (params: any):any => request('GET', '/official/coursewares', params)

export const getOfficialTags = (params: any):any => request('GET', '/official/tags', params)

export const copyOfficialCourseware = (params: any) => request('POST', '/teacher/courseware/copy-from-official', params)

export const getOfficialCoursewareInfo = (id: string):any => request('GET', `/official/courseware/${id}`)

export const addDownloadLog = (id: string) => request('POST', `/official/courseware/${id}/add-download-log`)

export const addViewLog = (id: string) => request('POST', `/official/courseware/${id}/add-view-log`)
// 获取区域分享的课件
export const copyInstitutionShareCourseware = (params: any) => request('POST', '/teacher/courseware/copy-from-area-public', params)

// 增加区域下载量日志
export const addInstitutionDownloadLog = (id: string) => request('POST', `/teacher/area-public-courseware/${id}/add-download-log`)
// 增加区域查看日志
export const addInstitutionViewLog = (id: string) => request('POST', `/teacher/area-public-courseware/${id}/add-view-log`)

// 获取区域分享课件列表
export const getInstitutionCourseware = (param: any):any => request('GET', '/teacher/area-public-coursewares', param)

// 获取区域分享课件的详情
export const getInstitutionCoursewareInfo = (id: string):any => request('GET', `/teacher/area-public-courseware/${id}`)

// 获取学校对应的局端信息
export const getInstitutionInfo = (id: string):any => request('GET', '/common/school-uplevel-ins-data', { school_id: id })
