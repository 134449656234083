
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'CollegeEntry',

  setup () {
    const visible = ref(true)
    const store = useStore()

    onMounted(() => {
      const customConfig = store.state.customConfig
      if (customConfig && +customConfig.logo === 1) {
        visible.value = false
      }
    })

    return {
      visible
    }
  }
})
