
import { onBeforeMount, onMounted, reactive, ref, toRaw } from 'vue'
import SearchBar from '@/components/common/SearchBar.vue'
import SelectBookModal from '@/views/songs/components/SelectBookModal.vue'
import * as service from './service'
import {
  bookType,
  CoursewareTypeOpts,
  CoursewareSortOpts,
  CoursewareType,
  CoursewareTypeMap,
  ListTypeMap,
  ShareModeOpts,
  showPrimaryOpts
} from './config'
import { debounce } from '@/utils/common'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { UnorderedListOutlined, AppstoreFilled } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { shareModeEnum } from '@/utils/dataMap'
import { moduleCodeEnum } from '@/utils/dataMap/teachingMaterial'
import PlatformList from './components/PlatformList.vue'
import SchoolList from './components/SchoolList.vue'
import InstitutionList from './components/InstitutionList.vue'
import PreviewModal from './components/PreviewModal.vue'
import CollegeEntry from '@/components/common/CollegeEntry.vue'
import {
  getSubjectEnum,
  getPressVersionEnum
} from '@/services/teaching-material'
import { usePageTableContext } from '@evideo/frontend-utils'
import PageContextKeyMap from '@/utils/pageTableContextMap'
import { getCloudSpaceInfo } from '../mine-coursewares/service'

export default {
  components: {
    SearchBar,
    SelectBookModal,
    UnorderedListOutlined,
    AppstoreFilled,
    PlatformList,
    PreviewModal,
    CollegeEntry,
    SchoolList,
    InstitutionList
  },
  setup () {
    // 列表数据
    const coursewaresList = ref([])

    // 搜索参数
    const queryParams = ref<any>({})

    const tagOpts = ref([])

    const router = useRouter()

    const store = useStore()

    const tagParams = reactive({
      pageSize: 50,
      page: 1,
      keyword: ''
    })

    let tableTabOpts = [
      { value: 'platform', label: '平台课件' },
      { value: 'institution', label: '区域课件' },
      { value: 'school', label: '学校课件' }
    ]

    const TableTypeMap = {
      PLATFROM: 'platform',
      SCHOOL: 'school',
      INSTITUTION: 'institution'
    }

    // 当前tab
    const currentTable = ref(TableTypeMap.PLATFROM)

    const listType = ref('card')

    const labelCol = {
      span: 6
    }
    const wrapperCol = {
      span: 18
    }

    const bookModalVisible = ref(false)

    const previewVisible = ref(false)

    const currentInfo = ref()

    const platformTableRef = ref()
    const schoolTableRef = ref()
    const institutionTableRef = ref()

    const subjectOpts = ref<any[]>([])
    const pressVersionOpts = ref<any[]>([])

    // 用于保存从页面上下文中获取到的页面数据
    const contextPage = ref<number>(1)

    const { getContextInfo, saveContextInfo, deleteContextInfo } = usePageTableContext()

    // 从页面上下文中获取保存的检索条件及当前页信息
    const handleGetPageContext = () => {
      const context = getContextInfo(PageContextKeyMap.COURSEWARE_SQUARE)
      const {
        queryParams: cParams,
        currentTable: cTable,
        currentPage: cPage,
        listType: lType,
        bookShowName: bName
      } = context
      if (cParams) { queryParams.value = cParams }
      if (cTable) { currentTable.value = cTable }
      if (lType) { listType.value = lType }
      if (bName) { bookShowName.value = bName }
      // 兼容第一次的时候页码不为1的情况
      if (cPage > 1) {
        contextPage.value = cPage
      } else { contextPage.value = 1 }
      deleteContextInfo(PageContextKeyMap.COURSEWARE_SQUARE)
    }

    const handleSavePageContext = () => {
      const page = handleGetRef().value.getCurrentPage()
      const pageContext = {
        queryParams: toRaw(queryParams.value),
        currentTable: toRaw(currentTable.value),
        currentPage: page,
        listType: toRaw(listType.value),
        bookShowName: toRaw(bookShowName.value)
      }
      saveContextInfo(PageContextKeyMap.COURSEWARE_SQUARE, pageContext)
    }

    // 获取学科信息
    const handleGetSubjectOpts = async () => {
      try {
        const data = await getSubjectEnum()
        subjectOpts.value = data.map(item => ({
          label: item.name,
          value: item.id
        }))
      } catch (err) {
        console.log(err)
        subjectOpts.value = []
      }
    }

    // 获取出版社信息
    const handleGetPressVersionOpts = async () => {
      try {
        const data = await getPressVersionEnum()
        pressVersionOpts.value = data.map(item => ({
          label: item.name,
          value: item.id
        }))
      } catch (err) {
        console.log(err)
        pressVersionOpts.value = []
      }
    }

    const handlePreviewPptByTableType = () => {
      let prefix = ''
      switch (currentTable.value) {
      case TableTypeMap.PLATFROM:
        prefix = 'official'
        break
      case TableTypeMap.SCHOOL:
        prefix = 'school'
        break
      case TableTypeMap.INSTITUTION:
        prefix = 'institution'
        break
      }
      if (prefix) {
        // 保存当前的搜索信息
        handleSavePageContext()
        router.push(`/resource/courseware-square/${prefix}/${currentInfo.value.id}/preview`)
      }
    }

    // 预览模块
    const handlePreview = (current: any) => {
      currentInfo.value = current

      if (currentInfo.value.type === CoursewareType.PPT) {
        handlePreviewPptByTableType()
        return
      } else if (currentInfo.value.type === CoursewareType.FLASH) {
        message.info('该类型课件暂不支持在线预览，请下载至本地后查看')
        return
      }
      previewVisible.value = true
    }

    const handleCancelPreview = () => {
      currentInfo.value = {}
      previewVisible.value = false
    }

    const bookShowName = ref('')

    const handleShowBookSelectModal = () => {
      bookModalVisible.value = true
    }
    // ok事件
    const handleSelectBookSection = (param) => {
      if (param.labels === undefined) {
        bookModalVisible.value = false
        handleResetForm()
      } else {
        bookModalVisible.value = false
        bookShowName.value = param.labels.join('-')
        queryParams.value.book_id = param.values[0] || null
        queryParams.value.book_section_id = param.values[1] || null
        console.log(bookShowName, queryParams)
      }
    }
    const getTagsOptions = debounce(async () => {
      const params = {
        per_page: tagParams.pageSize,
        current: tagParams.page,
        keyword: tagParams.keyword
      }
      tagOpts.value = (await service.getOfficialTags(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })

    const handleTagSearch = (v) => {
      tagParams.keyword = v
      tagParams.page = 1
      getTagsOptions()
    }

    const handleGetRef = () => {
      let tRef
      switch (currentTable.value) {
      case TableTypeMap.PLATFROM:
        tRef = platformTableRef
        break
      case TableTypeMap.SCHOOL:
        tRef = schoolTableRef
        break
      case TableTypeMap.INSTITUTION:
        tRef = institutionTableRef
        break
      }
      return tRef
    }

    const handleSearch = (param = {}) => {
      const tableRef = handleGetRef()
      console.log(tableRef)
      if (tableRef && tableRef.value.handleSearch) {
        tableRef.value.handleSearch({ ...param, page: 1 })
      }
    }

    const handleResetForm = async () => {
      queryParams.value = {}
      bookShowName.value = ''
      handleTagSearch('')
      handleSearch({ page: 1 })
    }

    const handleCheckCanAccessInstitutionList = async () => {
      const currentUser = store.state.currentUser
      const schoolId = currentUser?.school_id
      try {
        const insInfo = await service.getInstitutionInfo(schoolId)
        if (!insInfo || !insInfo?.uplevel_ins_data) {
          tableTabOpts = tableTabOpts.filter(info => info.value !== TableTypeMap.INSTITUTION)
        }
      } catch (error) {
        tableTabOpts = tableTabOpts.filter(info => info.value !== TableTypeMap.INSTITUTION)
      }
    }

    // 检查个人云空间容量
    const checkPersonalCloudSpace = () => {
      return new Promise((resolve, reject) => {
        getCloudSpaceInfo().then((res:any) => {
          if (res && res?.space_limit - res?.total_size > 0) {
            resolve(true)
            return
          }
          message.error('云空间不足,请删除不必要的资源')
          resolve(false)
        }).catch(err => {
          console.error('获取个人云空间信息失败：', err?.message)
          reject(err)
        })
      })
    }

    // 在beforeMount中做的原因是上下文保存的页码的赋值必须在组件mounted前
    onBeforeMount(() => {
      handleGetPageContext()
    })

    onMounted(async () => {
      await handleGetPressVersionOpts()
      await handleGetSubjectOpts()
      await handleCheckCanAccessInstitutionList()
      await handleTagSearch('')
    })

    return {
      subjectOpts,
      pressVersionOpts,
      coursewaresList,
      queryParams,
      bookShowName,
      bookModalVisible,
      bookType,
      tagOpts,
      CoursewareTypeOpts,
      CoursewareTypeMap,
      labelCol,
      wrapperCol,
      CoursewareSortOpts,
      CoursewareType,
      previewVisible,
      currentInfo,
      listType,
      ListTypeMap,
      store,
      ShareModeOpts,
      shareModeEnum,
      showPrimaryOpts,
      moduleCodeEnum,
      tableTabOpts,
      TableTypeMap,
      currentTable,
      platformTableRef,
      schoolTableRef,
      institutionTableRef,
      contextPage,
      handleShowBookSelectModal,
      handleSelectBookSection,
      handleTagSearch,
      handlePreview,
      handleCancelPreview,
      handleSearch,
      handleResetForm,
      checkPersonalCloudSpace
    }
  }

}
