import { request } from '@/utils/http'

export const queryTeacherCoursewareList = (params: any) =>
  request('get', '/teacher/courseware-space-nodes', params)

// 教师空间节点创建
export const createSpaceNode = (params: any) =>
  request('POST', '/teacher/courseware-space-node/create', params)

export const editSpaceNode = (id: string, params: any) =>
  request('POST', `/teacher/courseware-space-node/${id}/edit`, params)

export const deleteSpaceNode = (id: string) =>
  request('DELETE', `/teacher/courseware-space-node/${id}/delete`)

// 搜索课件
export const searchTeacherCoursewares = (params: any) =>
  request('GET', '/teacher/coursewares', params)

// 创建教师课件
export const createTeacherCourseware = (params: any) =>
  request('POST', '/teacher/courseware/create', params)

// 编辑教师课件
export const editTeacherCourseware = (id: string, params: any) =>
  request('POST', `/teacher/courseware/${id}/edit`, params)

// 删除课件
export const deleteTeacherCourseware = (id: string) =>
  request('DELETE', `/teacher/courseware/${id}/delete`)

// 获取教材详情
export const getTeacherCourseware = (id: string): any =>
  request('GET', `/teacher/courseware/${id}`)

// 重命名课件
export const renameCourseware = (id: string, params: any) =>
  request('POST', `/teacher/courseware/${id}/rename`, params)

// 获取官方的标签信息
export const getOfficialTags = (params: any): Promise<any> =>
  request('GET', '/official/tags', params)

export const shareCourseware = (id: string, params: any) =>
  request('POST', `/teacher/courseware/${id}/share`, params)

export const getShareList = (params: any): any =>
  request('GET', '/teacher/courseware/share-records', params)

// 根据学校id获取上级机构信息
export const getTeacherAreaInfo = (params: { school_id: string }): any =>
  request('GET', '/common/school-uplevel-ins-data', params)

export const cancelShare = (params: { share_record_id: number }): any =>
  request('POST', '/teacher/courseware/cancel-share', params)

// 获取可被关联的ppt课件列表
export const getAssociatedPpt = (id: string, params: any) =>
  request('GET', `/teacher/courseware/${id}/relatePPTCoursewares`, params)

// 获取可被关联的说课列表
export const getRelateSpeakCoursewares = (id: string, params: any) =>
  request(
    'GET',
    `/teacher/courseware/${id}/relateSpeakLessonCoursewares`,
    params
  )

// 获取可被关联的教案列表
export const getRelatePlanCoursewares = (id: string, params: any) =>
  request(
    'GET',
    `/teacher/courseware/${id}/relateTeachPlanCoursewares`,
    params
  )

// 获取官方教案模板
export const getOfficialPlanTemplate = (params: any) =>
  request('GET', '/teacher/teach-plan-templates', params)

// 获取官方教案模板详情
export const getOfficialPlan = (id: string, params: any) =>
  request('GET', `/teacher/teach-plan-templates/${id}`, params)

// 查看分享到学校的课件分享记录详情
export const getTeacherShareCourseware = (id: string): any =>
  request('GET', `/school/teacher-courseware-share-record/${id}`)

// 获取云空间数据
export const getCloudSpaceInfo = () => request('GET', '/teacher/profile/space')
