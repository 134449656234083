export const bookType = {
  OFFICIAL: 1
}

export const CoursewareTypeOpts = [
  { label: 'PPT', value: 'ppt' },
  { label: '视频', value: 'video' },
  { label: '音频', value: 'audio' },
  { label: '图片', value: 'image' },
  { label: 'flash', value: 'swf' },
  { label: '说课', value: 'speak' },
  { label: '教案', value: 'plan' }
]

export const CoursewareSortOpts = [
  { label: '按id升序', value: 'id-asc' },
  { label: '按id降序', value: 'id-desc' },
  // { label: '按人气升序', value: 'popularity-asc' },
  { label: '按人气降序', value: 'popularity-desc' },
  // { label: '按下载次数升序', value: 'download_num-asc' },
  { label: '按下载次数降序', value: 'download_num-desc' }
]

export const CoursewareType = {
  PPT: 'ppt',
  AUDIO: 'audio',
  VIDEO: 'video',
  IMAGE: 'image',
  FLASH: 'swf',
  SPEAK: 'speak',
  PLAN: 'plan'
}

export const CoursewareTypeMap = {
  ppt: 'PPT',
  audio: '音频',
  video: '视频',
  image: '图片',
  swf: 'flash动画',
  speak: '说课',
  plan: '教案'
}

export const ListTypeMap = {
  CARD: 'card',
  LIST: 'list'
}

export const ShareModeOpts = [
  { label: '无限制', value: 0 },
  { label: '禁止获取', value: 1 },
  { label: '允许获取，禁止修改', value: 2 }
  // { label: '允许获取修改，禁止分享', value: 3 }
]

export const showPrimaryOpts = [
  { label: '是', value: 1 },
  { label: '否', value: 0 }
]

// 格栅设置
export const GutterConfig = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 4
}
