<template>
  <router-link
    v-if="visible"
    class="content"
    :to="`/college`"
  >
    <img :src="require('/src/assets/college/college.png')" > 魔欢学苑
  </router-link>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'CollegeEntry',

  setup () {
    const visible = ref(true)
    const store = useStore()

    onMounted(() => {
      const customConfig = store.state.customConfig
      if (customConfig && +customConfig.logo === 1) {
        visible.value = false
      }
    })

    return {
      visible
    }
  }
})
</script>

<style lang="less" scoped>
.content{
  font-size: 18px;
}

</style>
