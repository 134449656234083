
import { computed, createVNode, ref } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons-vue'
import { shareModeEnum } from '@/utils/dataMap'
import CardNameTitle from './CardNameTitle.vue'

export default {
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  emits: ['onPreview', 'onCopy'],
  components: {
    UserOutlined,
    CardNameTitle
  },
  setup (props, { emit }) {
    const coursewareInfo = computed(() => props.value)
    const coursewareName = ref(coursewareInfo.value.name)

    const handlePreview = () => {
      emit('onPreview', coursewareInfo.value)
    }

    const handleShowWarning = () => {
      if (coursewareInfo.value.share_mode === shareModeEnum.NOCOPY) {
        message.error('该课件已被作者设置为禁止获取，您无权限获取该课件')
        return
      }
      Modal.confirm({
        title: '是否确认将课件添加到我的课件中',
        icon: createVNode(ExclamationCircleOutlined),
        closable: true,
        onOk: handleCopy,
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    const handleCopy = () => {
      console.log('copy')
      emit('onCopy', coursewareInfo.value)
    }

    return {
      coursewareInfo,
      coursewareName,
      shareModeEnum,
      handlePreview,
      handleCopy,
      handleShowWarning
    }
  }

}
