<template>
  <a-card class="card_body">
    <a-row :gutter="[16, 16]" @click="handlePreview">
      <a-col :span="6">
        <img class="courseware_icon" src="@/assets/coursewares/audio_courseware.png" />
      </a-col>
      <a-col :span="18">
        <!-- <h3 v-if="coursewareName.length < 10"><a-tag color="#be9772">音频</a-tag>{{ coursewareName }}</h3>
        <a-tooltip v-else :title="coursewareName">
          <h3><a-tag color="#be9772">音频</a-tag>{{ coursewareName.slice(0, 8) }}...</h3>
        </a-tooltip> -->
        <card-name-title-vue type="音频" :name="coursewareName"></card-name-title-vue>
        <!-- 作者信息 -->
        <div>
          <user-outlined />
          <span style="padding-left: 5px;">
            <span v-if="coursewareInfo.author_name.length < 4">
              {{ coursewareInfo.author_name }}
            </span>
            <a-tooltip v-else :title="coursewareInfo.author_name">
              {{ coursewareInfo.author_name.slice(0, 3) }}...
            </a-tooltip>
          </span>
          <span style="padding-left: 7px;">{{
            coursewareInfo.created_at
          }}</span>
        </div>
        <div>
          <img class="popularity" src="@/assets/coursewares/popularity.png" />
          <span style="padding-left: 5px">{{
            coursewareInfo?.popularity?.popularity || 0
          }}</span>
          <img class="download" src="@/assets/coursewares/download.png" />
          <span style="padding-left: 5px">{{
            coursewareInfo?.popularity?.download_num || 0
          }}</span>
        </div>
      </a-col>
    </a-row>
    <template class="ant-card-actions" #actions>
      <div @click="handlePreview">预览</div>
      <div @click="handleShowWarning">获取</div>
    </template>
  </a-card>
</template>

<script lang="ts">
import { computed, createVNode, ref } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons-vue'
import { shareModeEnum } from '@/utils/dataMap'
import CardNameTitleVue from './CardNameTitle.vue'

export default {
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  emits: ['onPreview', 'onCopy'],
  components: {
    UserOutlined,
    CardNameTitleVue
  },
  setup(props, { emit }) {
    const coursewareInfo = computed(() => props.value)
    const coursewareName = ref(coursewareInfo.value.name || '')

    const handlePreview = () => {
      emit('onPreview', coursewareInfo.value)
    }

    const handleShowWarning = () => {
      if (coursewareInfo.value.share_mode === shareModeEnum.NOCOPY) {
        message.error('该课件已被作者设置为禁止获取，您无权限获取该课件')
        return
      }
      Modal.confirm({
        title: '是否确认将课件添加到我的课件中',
        icon: createVNode(ExclamationCircleOutlined),
        closable: true,
        onOk: handleCopy,
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    const handleCopy = () => {
      emit('onCopy', coursewareInfo.value)
    }

    return {
      coursewareInfo,
      coursewareName,
      shareModeEnum,
      handlePreview,
      handleCopy,
      handleShowWarning
    }
  }
}
</script>

<style scoped>
.card_body {
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.courseware_icon {
  width: 100%;
}

.popularity {
  width: 15px;
  height: 15px;
}

.download {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}
</style>

<style lang="less" scoped>
::v-deep .ant-card-body {
  padding: 24px 12px;
}
</style>
